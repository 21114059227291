<template>
  <section class="block paginate_block general">
    <CommonTitle :options="options_title"/>
    <CommonTable :options="options_table"/>
  </section>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import CommonTable from '../../common/CommonTable';
import CommonTitle from '../../common/CommonTitle';

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'PrizeListMain',

  components: {
    CommonTitle,
    CommonTable,
  },

  data: () => ({
    page: 0,
    limit: 100,
    options_title: {
      name: 'Призы',
      id: 'prize',
      actions: '',
    },
    options_table: {
      id: 'prize',
      request: 'prizes',
      actions: 'edit-remove',
      ths: [
        { id: 'img', name: 'Миниатюра', width: '10%', value: '', sort: false, search: false, type: 'image' },
        { id: 'name', name: 'Название', width: '20%', value: '', sort: true, search: true, type: 'textarea' },
        { id: 'description', name: 'Описание (не реализовано)', width: '65%', value: '', sort: false, search: true, type: 'textarea' },
        { id: '', name: '', width: '5%', value: '', sort: false, search: false },
      ],
      table: {
        data: []
      }
    }
  }),

  computed: {
    ...mapGetters([
      'stocksList',
      'prizesList',
      'storageUrl',
    ]),
  },

  watch: {
    prizesList: {
      deep: true,
      immediate: true,
      handler: 'reRenderTable',
    },
  },

  mounted() {
    this.$bus.$on('createPrize', res => this.addPrize(res.data));
    this.$bus.$on('removePrize', res => this.removePrizeById(res.data));
    this.$bus.$on('editPrize', res => this.editPrize(res.data));
  },

  beforeDestroy() {
    this.$bus.$off('createPrize');
    this.$bus.$off('editPrize');
    this.$bus.$off('removePrize');
  },

  methods: {
    ...mapActions(['getPrizes']),
    ...mapMutations([
      'addPrize',
      'editPrize',
      'removePrizeById',
    ]),

    reRenderTable() {
      this.$set(this.options_table, 'table', {
        data: this.convertPrizesToTable(this.prizesList),
        variables: this.options_table.ths.map(el => el.id).filter(el => el),
        limit: this.limit,
        page: this.page,
        total: _.size(this.prizesList),
      });
    },

    convertPrizesToTable(itemList) {
      const extendImageUrl = img => this.storageUrl + img + `#${ Date.now() }`;
      return _.values(itemList).map(item => ({ ...item, img: extendImageUrl(item.img) }));
    },
  },
};
</script>