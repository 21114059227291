<template>
  <section class="block paginate_block general">
    <CommonTitle :options="options_title"/>
    <CommonTable 
      :options="options_table" 
      @set-child="setChild"
      @create-modal="onCreateModal"
    />
  </section>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import CommonTable from '../../common/CommonTable';
import CommonTitle from '../../common/CommonTitle';

import { statuses as stockStatuses } from '@/constants/stock.js';
import { categories as prizeCategories } from '@/constants/prize.js';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'StockListMain',

  components: {
    CommonTitle,
    CommonTable,
  },

  data: ths => ({
    lodash: _,
    page: 0,
    limit: 100,
    options_title: {
      name: 'Розыгрыши',
      id: 'stock',
      actions: '',
    },
    options_table: {
      id: 'stock',
      request: 'stocks',
      actions: 'edit-remove',
      ths: [
        { name: '#', width: '2%', type: 'index' },
        { id: 'fullname', name: 'Полное название', width: '22%', value: '', sort: true, search: true },
        { id: 'name', name: 'Название\nдля игры', width: '10%', value: '', sort: true, search: true },
        { id: 'start_date', name: 'Дата начала', width: '6%', value: '', sort: true, search: true },
        { id: 'end_date', name: 'Дата окончания', width: '6%', value: '', sort: true, search: true },
        { id: 'description', name: 'Текст сообщения для\nигрока-участника', width: '20%', value: '', sort: false, search: true },
        { id: 'prizesSize', name: 'Список\nпризов', width: '7%', value: '', sort: false, search: true, child: 'prizes' },
        { id: 'membersSize', name: 'Топ 30\nигроков', width: '7%', value: '', sort: true, search: true, child: 'members' },
        { id: 'winnersSize', name: 'Победители', width: '7%', value: '', sort: true, search: true },
        { id: 'status', name: 'Статус', width: '10%', value: '', sort: true, search: true },
        { id: '', name: '', width: '5%', value: '', sort: false, search: false },
      ],
      table: {
        data: [],
        activeChild: null,
        activeChildColor: null,
        activeChildIndex: null,
        activeLastUpdate: null,
        childred: {
          prizes: {
            dir: 'prizes',
            id: 'stock/prizes',
            request: 'stocks/child/prizes',
            actions: 'edit-remove',
            ths: [
              { name: '#', width: '2%', type: 'index' },
              { id: 'img', name: 'Миниатюра', width: '10%', value: '', sort: false, search: false, type: 'image' },
              { id: 'name', name: 'Название', width: '20%', value: '', sort: true, search: true, type: 'textarea' },
              { id: 'description', name: 'Описание\n(не реализовано)', width: '23%', value: '', sort: true, search: true, type: 'textarea' },
              { id: 'category', name: 'Категория\n(не реализовано)', width: '20%', value: '', sort: true, search: true },
              { id: 'quantity', name: 'Количество\n(не реализовано)', width: '20%', value: '', sort: true, search: true },
              { id: '', name: '', width: '5%', value: '', sort: false, search: false },
            ],
            table: {
              variables: ['#', 'img', 'name', 'description', 'category', 'quantity'],
            }, 
          },
          members: {
            dir: 'members',
            id: 'stock/members',
            request: 'stocks/child/members',
            actions: 'edit',
            ths: [
              { name: '#', width: '2%', type: 'index' },
              { id: 'login', name: 'Логин', width: '15%', value: '', sort: true, search: true },
              { id: 'email', name: 'Почта', width: '30%', value: '', sort: true, search: true },
              { id: 'type', name: 'Подписка', width: '15%', value: '', sort: true, search: true },
              { id: 'score', name: 'Баллы', width: '10%', value: '', sort: true, search: true },
              { id: 'prize', name: 'Приз', width: '25%', value: '', sort: false, search: true },
              { id: '', name: '', width: '5%', value: '', sort: false, search: false },
            ],
            table: {
              variables: ['#', 'login', 'email', 'type', 'score', 'prize'],
            },  
          },
          winners: {
            dir: 'winners',
            id: 'stock/winners',
            request: 'stocks/child/winners',
            actions: 'edit-remove',
            ths: [
              { name: '#', width: '2%', type: 'index' },
              { id: 'position', name: 'Место', width: '8%', value: '', sort: true, search: false },
              { id: 'login', name: 'Логин', width: '20%', value: '', sort: true, search: true },
              { id: 'email', name: 'Почта', width: '30%', value: '', sort: true, search: true },
              { id: 'score', name: 'Баллы', width: '10%', value: '', sort: true, search: true },
              { id: 'prize', name: 'Приз', width: '25%', value: '', sort: false, search: true },
              { id: '', name: '', width: '5%', value: '', sort: false, search: false },
            ],
            table: {
              variables: ['#', 'position', 'login', 'email', 'score', 'prize'],
            }, 
          },
        },
      }
    }
  }),

  computed: {
    ...mapGetters([
      'stocksList',
      'prizesList',
      'usersList',
      'usersListWithUser',
      'storageUrl',
    ]),
  },

  watch: {
    stocksList: {
      deep: true,
      immediate: true,
      handler: 'reRenderTable',
    },    
    prizesList: {
      deep: true,
      handler: 'reRenderTable',
    },
    usersList: {
      deep: true,
      handler: 'reRenderTable',
    },
  },

  mounted() {
    this.$bus.$on('createStock', result => {
      this.addStock(result.data);
      this.$nextTick(() => {
        const name = 'prizes';
        const index = _.keys(this.stocksList).findIndex(stockId => stockId === result.data._id);
        this.setChild({ name, index });
      });
    });
    this.$bus.$on('editStock', result => {
      this.editStock(result.data);
      this.$nextTick(this.reRenderTableChild);
    });
    this.$bus.$on('removeStock', result => {
      this.removeStock({ stockId: result.data });
      this.$nextTick(this.reRenderTableChild);
    });

    this.$bus.$on('createStockPrize', result => {
      this.createPrizeFromStock({
        stockId: result.data._idStock,
        prizeId: result.data._id,
        prize: result.data,
      });
    });
    this.$bus.$on('editStockPrize', result => {
      this.editPrizeFromStock({
        stockId: result.data._idStock,
        prizeId: result.data._id,
        prize: result.data,
      });
    });
    this.$bus.$on('removeStockPrize', result => {
      this.removePrizeFromStock({
        stockId: result.data._idStock,
        prizeId: result.data._id,
      });
    });

    this.$bus.$on('editStockMember', result => {
      this.editMemberFromStock({
        stockId: result.data._idStock,
        userId: result.data._id,
        prize: result.data.prize_id,
        score: result.data.score,
      });
    });
  },

  beforeDestroy() {
    this.$bus.$off('createStock');
    this.$bus.$off('editStock');
    this.$bus.$off('removeStock');
    this.$bus.$off('createStockPrize');
    this.$bus.$off('editStockPrize');
    this.$bus.$off('removeStockPrize');
    this.$bus.$off('editStockMember');
  },

  methods: {
    ...mapActions([
      'getStocks', 
      'editMemberFromStock',
    ]),
    ...mapMutations([
      'addStock',
      'editStock',
      'removeStock',
      'setSelectedUserId',
      'setSelectedStockId', 
      'setSelectedPrizeId',
      'createPrizeFromStock',
      'editPrizeFromStock',
      'removePrizeFromStock',
    ]),

    reRenderTable() {
      const staticKeys = [
        'childred', 
        'activeChild', 
        'activeChildColor', 
        'activeChildIndex', 
        'activeLastUpdate',
      ];
      this.$set(this.options_table, 'table', {
        ..._.pick(this.options_table.table, staticKeys),
        data: this.convertStocksToTable(this.stocksList),
        variables: ['#'].concat(this.options_table.ths.map(el => el.id).filter(el => el)),
        limit: this.limit,
        page: this.page,
        total: _.size(this.stocksList),
      });
    },

    reRenderTableChild() {
      this.setChild({
        index: this.options_table.table.activeChildIndex,
        name: this.options_table.table.activeChild,
        igrone: true,
      });
    },

    setChild({ name, index, igrone }) {
      const { activeChildIndex, activeChild } = this.options_table.table;
      if (activeChildIndex === index && activeChild === name && !igrone) {
        this.$set(this.options_table.table, 'activeChildIndex', null);
        this.$set(this.options_table.table, 'activeChildColor', null);
        this.$set(this.options_table.table, 'activeChild', null);
        this.setSelectedStockId(null);
      } else {
        this.$set(this.options_table.table, 'activeChildIndex', index);
        this.$set(this.options_table.table, 'activeChild', name);
        const selectedStock = this.options_table.table.data.find((_, i) => i === index);
        if (selectedStock) {
          const color = _.get(stockStatuses.find(s => s.name === selectedStock.status), 'color', null);
          this.$set(this.options_table.table, 'activeChildColor', color);
          this.setSelectedStockId(selectedStock._id);
        };
      };
      this.$set(this.options_table.table, 'activeLastUpdate', Date.now());
    },

    onCreateModal({ modal, element }) {
      switch(modal) {
        case 'stock/prizes-edit':
          this.setSelectedPrizeId(element._id);
      };
    },

    convertStocksToTable(stocksList) {
      return _.map(stocksList, (stock, stockId) => {
        const members = this.convertMembersByStock(stock);
        const winners = this.convertWinnersByStock(stock);
        const prizes = this.convertPrizesByStock(stock);
        const prizesSize = prizes.reduce((acc, p) => acc + parseInt(p.quantity), 0);
        return {
          ...stock,
          _id: stockId,
          status: _.get(stockStatuses.find(s => s._id === stock.status), 'name', null),
          prizes,  prizesSize: `${prizes.length}(${prizesSize})`,
          winners, winnersSize: `${winners.length} из ${prizesSize}`,
          members, membersSize: `${Math.min(30, stock.membersSize)} из ${stock.membersSize}`,
        };
      });
    },

    convertWinnersByStock(stock) {
      return [];
      // return _.keys(stock.members).map(userId => {
      //   return this.usersList[userId];
      // }).filter(e => e).sort((a, b) => b.score - a.score).map((a, i) => {
      //   a.position = i + 1;
      //   return a
      // });
    },  

    convertMembersByStock(stock) {
      // return [];
      return _.reduce(stock.members, (acc, userOnStock, userId) => {
        const userOnStorage = this.usersListWithUser[userId];
        if (userOnStorage) {
          const _id = userId;
          const _idStock = stock._id;
          const login = userOnStorage.login || userOnStorage.phone;
          const { score = 0, prize: prize_id = null } = _.get(userOnStorage.stocks, stock._id, {});
          const prize = _.get(this.prizesList[prize_id], 'name');
          let category = _.get(stock.prizes[prize_id], 'category');
          category = _.get(prizeCategories.find(s => s._id === category), 'name', null);
          acc.push(_.assign({}, userOnStorage, { _id, _idStock, login, score, prize, prize_id, category }));
        };
        return acc;
      }, []).sort((a, b) => b.score - a.score);
    },

    convertPrizesByStock(stock) {
      return _.reduce(stock.prizes, (acc, prizeOnStock, prizeId) => {
        const prizeOnStorage = this.prizesList[prizeId];
        if (prizeOnStorage) {
          acc.push({
            ...prizeOnStorage,
            ...prizeOnStock,
            _id: prizeId,
            _idStock: stock._id,
            category: _.get(prizeCategories.find(s => s._id === prizeOnStock.category), 'name', null),
            img: this.storageUrl + prizeOnStorage.img,
          });
        };
        return acc;
      }, []);
    },
  },
};
</script>