import { render, staticRenderFns } from "./StockMain.vue?vue&type=template&id=21f6e579&scoped=true&"
import script from "./StockMain.vue?vue&type=script&lang=js&"
export * from "./StockMain.vue?vue&type=script&lang=js&"
import style0 from "./StockMain.vue?vue&type=style&index=0&id=21f6e579&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21f6e579",
  null
  
)

export default component.exports