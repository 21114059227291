<template>
  <section class="pages">
    <div class="pages__list">
      <div 
        v-for="page of pages" 
        :key="page.name" 
        :class="{ active: selectedPage === page.component }" 
        @click="selectedPage = page.component"
        v-text="page.name"
      />
    </div>
    <component :is="selectedPage"/>
  </section>
</template>

<script>
import _ from 'lodash';

import StockListMain from './pages/StockListMain';
import PrizeListMain from './pages/PrizeListMain';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'StockMain',

  data: () => ({
    selectedPage: StockListMain,
    pages: [
      { name: 'Список розыгрышей', component: StockListMain },
      { name: 'Список призов', component: PrizeListMain },
    ],
    limit: 100,
    page: 0,
  }),

  computed: {
    ...mapGetters([
      'prizesList',
    ]),
  },

  async beforeMount() {
    const filter = {
      limit: this.limit,
      page: this.page,
    };
    await this.getStocks({ filter });
    const find = { _id: { $nin: _.keys(this.prizesList) } };
    this.getPrizes({ filter, find });
  },

  methods: {
    ...mapActions([
      'getStocks',
      'getPrizes',
    ])
  },
};
</script>

<style lang="scss" scoped>
.pages {
  flex-grow: 1;
  overflow: auto;
  &__list {
    display: flex;
    padding: 25px 25px 0px 25px;
    > div {
      color: #00325C;
      font-weight: 500;
      font-size: 20px;
      cursor: pointer;
      padding: 5px 12px 15px 12px;
      &:not(:last-child) {
        margin-right: 5px;
      }
      &:hover {
        color: #054b85;
        transition: 0.3s all;
      }
      &.active {
        box-shadow: inset 0 -3px 0 #6461F6;
      }
    }
  }
}
</style>