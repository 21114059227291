<template>
  <StockMain />
</template>

<script>
import StockMain from '@/components/stock/StockMain';

export default {
  name: 'Stock',
  components: {
    StockMain
  }
}
</script>

<style>

</style>